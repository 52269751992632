
document.querySelectorAll(".page-header video").forEach(($video) => {
  const $container = $video.closest(".page-header");
  const $toggle = $container.querySelector("button");

  $toggle.addEventListener("click", () => {
    const pressed = $toggle.getAttribute("aria-pressed") === "true";
    if (pressed) {
      $video.play();
    } else {
      $video.pause();
    }

    $toggle.setAttribute("aria-pressed", !pressed);
  });
});
