document.querySelectorAll(".carousel-container").forEach(carousel => {
  const slides = carousel.querySelectorAll(".slide");
  const scrollContainer = carousel.querySelector(".scroll-container");

  let visibleCount = getVisibleCount();
  let pages = Math.ceil(slides.length / visibleCount);

  if (slides.length <= visibleCount) {
    carousel.querySelector("nav")?.remove();
    return;
  }

  const prev = carousel.querySelector("a.prev");
  const next = carousel.querySelector("a.next");
  const indicator = carousel.querySelector("ul.indicator");

  let activeSlide = 0;
  let activePage = 0;

  prev.style.visibility = "hidden";

  for (let i = 0; i < pages; i++) {
    indicator.innerHTML +=
      `<li class="${i === 0 ? "active" : ""}">
      <span class="sr-only">${i}</span>
    </li>`;
  }

  indicator.addEventListener("click", event => {
    if (event.target.tagName !== "LI") return;

    let idx = Array.from(indicator.children).indexOf(event.target);
    activePage = idx;
    activeSlide = idx * visibleCount;
    adjustSlides();
  });

  prev.addEventListener("click", event => {
    event.preventDefault();
    if (activeSlide <= 0) return;
    activeSlide -= visibleCount;
    activePage--;
    adjustSlides();
  });

  next.addEventListener("click", event => {
    event.preventDefault();
    if ((activeSlide + visibleCount) >= (slides.length)) return;
    activeSlide += visibleCount;
    activePage++;
    adjustSlides();
  });

  window.addEventListener('load', function () {
    // Remove lazy loading so images will preload
    carousel.querySelectorAll("img").forEach(img => {
      img.removeAttribute("loading");
    });
  });

  function adjustSlides() {
    let offset = slides[activeSlide].offsetLeft;
    scrollContainer.scrollTo({
      left: offset,
      behavior: "smooth"
    });

    prev.style.visibility = "visible";
    next.style.visibility = "visible";

    if (activeSlide === 0) {
      prev.style.visibility = "hidden";
    }
    if (activeSlide + visibleCount >= slides.length) {
      next.style.visibility = "hidden";
    }

    indicator.querySelector(".active").classList.remove("active");
    Array.from(indicator.children).at(activePage).classList.add("active");
  }

  function getVisibleCount() {
    let visibleCount = 0;
    slides.forEach(slide => {
      if (slide.offsetLeft < scrollContainer.clientWidth) {
        visibleCount++;
      }
    });

    return visibleCount;
  }
});
