// TODO: Add additional for different modal types needed
document.querySelectorAll("a[rel*='lightbox'][href*='.mp4']").forEach(opener => {
  opener.addEventListener("click", click => {
    click.preventDefault();

    let video = document.createElement("video");
    if (opener.getAttribute("data-poster"))  {
      video.poster = opener.getAttribute("data-poster");
    } else if (opener.querySelector("img")) {
      video.poster = opener.querySelector("img").src;
    } else {
      video.poster = opener.href.replace(".mp4", ".jpg");
    }
    video.controls = true;
    video.controlsList = "nodownload noplaybackrate";
    video.disablePictureInPicture = true;
    video.autoplay = true;
    video.src = opener.href;

    showModal(video);

  });
});

document.querySelectorAll("a[rel*='lightbox'][href*='youtu']").forEach(opener => {
  opener.addEventListener("click", click => {
    click.preventDefault();

    let rx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
    let videoId = opener.href.match(rx).pop(1);

    let iframe = `
      <iframe
        src="https://www.youtube.com/embed/${videoId}?autoplay=1&modestbranding=1&rel=0"
        style="width:1200px;height:auto;aspect-ratio:16/9;max-width:90vw;max-height:90vh"
        allow="autoplay"
        frameborder="0"
        allowfullscreen>
      </iframe>`;

    showModal(iframe);
  });
});

document.querySelectorAll(`
  a[rel*='lightbox'][href$='.jpg'],
  a[rel*='lightbox'][href$='.png'],
  a[rel*='lightbox'][href$='.webp'],
  a[rel*='lightbox'][href$='.svg']
  `).forEach(opener => {
  opener.addEventListener("click", click => {
    click.preventDefault();

    let thumbnail = opener.querySelector("img");

    if (thumbnail.alt.length > 0) {
      let figure = `
        <figure>
          <img src="${opener.href}" alt="${thumbnail.alt}" />
          <figcaption>${thumbnail.alt}</figcaption>
        </figure>`;
      showModal(figure);
    } else {
      let image = `<img src="${opener.href}" alt="${thumbnail.alt}">`;
      showModal(image);
    }
  });
});

function showModal(content, start) {
  document.querySelector("dialog.modal")?.remove();
  document.querySelector("div.backdrop")?.remove();

  let modal = document.createElement("dialog");
  modal.classList.add("modal");
  document.body.append(modal);

  let close = document.createElement("button");
  close.classList.add("close");
  modal.append(close);
  close.addEventListener("click", closeModal);

  let backdrop = document.createElement("div");
  backdrop.classList.add("backdrop");
  backdrop.addEventListener("click", closeModal);
  document.body.append(backdrop);

  if (typeof content === "string") {
    modal.insertAdjacentHTML("beforeend", content);
  } else {
    modal.append(content);
  }

  modal.show();
  modal.querySelector("video")?.play();

  close.addEventListener("click", closeModal);
  backdrop.addEventListener("click", closeModal);

  function closeModal() {
    modal.close();
    modal.remove();
    backdrop.remove();
  }
}
