function getUrlParameter(name) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  const results = regex.exec(location.search);
  return results === null ? '' : results[1];
};

document.addEventListener("DOMContentLoaded", function() {
  const q = getUrlParameter("q").replace(" ", "+");
  let start = getUrlParameter("start");
  if (start === "") start = "1";

  const resultsDiv = document.getElementById("searchResults");
  if (!resultsDiv) return;

  const key = resultsDiv.getAttribute("data-key");

  fetch(`https://search.sam-cms.com?q=${q}&start=${start}&s=${key}`)
  .then(function (response) {
    return response.text();
  })
  .then(function(response) {
    resultsDiv.innerHTML = response;
  })
  .catch(function (err) {
    console?.log(err);
  });

});
