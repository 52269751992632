// Open mobile menu
document.querySelector("[aria-controls='primary-nav']").addEventListener("click", function (event) {
  const button = event.target;
  const state = button.getAttribute("aria-expanded");
  const isOpen = state === "true";
  const mobileMenu = document.getElementById("nav-container");
  const siteHeader = document.querySelector(".site-header");
  const body = document.querySelector("body");

  button.setAttribute("aria-expanded", isOpen ? "false" : "true");
  button.innerText = isOpen ? "Menu" : "Close";
  body.classList.toggle("max-lg:overflow-hidden");
  mobileMenu.classList.toggle("hidden");
  siteHeader.classList.toggle("opened");
});

// Calculate if mobile menu being used
let isMobile = document.querySelector("[aria-controls='primary-nav']").offsetParent !== null;
let resizeTimer;

function resizeHandler() {
  clearTimeout(resizeTimer);
  resizeTimer = setTimeout(() => {
    isMobile = document.querySelector("[aria-controls='primary-nav']").offsetParent !== null;
  }, 1000);
}

window.addEventListener("resize", resizeHandler);

// Handle primary nav hover/clicks
const primaryParents = document.querySelectorAll(".site-header:not(.menu-open) nav > ul > .isParent");

primaryParents.forEach(primary => {
  primary.addEventListener("mouseenter", event => {
    !isMobile && openMenu(primary);
  });

  primary.addEventListener("mouseleave", event => {
    !isMobile && closeMenu(primary);
  });

  primary.addEventListener("click", event => {
    isMobile && toggleMenu(primary);
  });

  primary.querySelector("button").addEventListener("click", event => {
    !isMobile && toggleMenu(primary);
  });

  primary.querySelector(":scope > a").addEventListener("click", event => {
    if (isMobile) {
      event.preventDefault();
    } else {
      return;
    }
  });

});

function toggleMenu(parent) {
  if (parent.classList.contains("open")) {
    closeMenu(parent);
  } else {
    openMenu(parent);
  }
};

function openMenu(parent) {
  const button = parent.querySelector("button");

  parent.parentNode.querySelectorAll(".isParent.open").forEach(li => { li.classList.remove("open") })

  parent?.classList.add("open");
  button?.setAttribute("aria-expanded", "true");
}

function closeMenu(parent) {
  const button = parent.querySelector("button");

  parent?.classList.remove("open");
  button?.setAttribute(`aria-expanded`, `false`);
}

let timerId;

function throttle(cb, delay = 250) {
  if (timerId) return;
  timerId = setTimeout(() => {
    cb();
    timerId = undefined;
  }, delay);
}

// show sticky nav on scroll up only
const header = document.querySelector(".site-header");
const headerHeight = header.offsetHeight;
let lastScroll = 0;

let scrollCallback = () => {
  let currentScroll = window.pageYOffset;

  if (currentScroll > headerHeight && currentScroll - lastScroll < 0) {
    header.classList.add("stuck");
    header.classList.remove("collapsed");
  } else if (currentScroll > headerHeight) {
    header.classList.remove("stuck");
    header.classList.add("collapsed");
  } else {
    header.classList.remove("stuck");
    header.classList.remove("collapsed");
  }
  lastScroll = currentScroll;
};

window.addEventListener("scroll", () => {
  throttle(scrollCallback, 150);
} , false);
