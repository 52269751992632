if (document.querySelector("#google-map")) {
  let options, map, infoWindow;
  let APIKey = document.querySelector("#google-map").getAttribute("data-api");
  let locationsRSS = document.querySelector("#google-map").getAttribute("data-locations-rss");

  options = {
    zoom: 5,
    center: {
      lat: 36.30954493102984,
      lng: -91.3671875,
    },
    disableDefaultUI: true,
    zoomControl: true,
    fullscreenControl: true,
    mapTypeControl: true,
    minZoom: 4,
    restriction: {
      latLngBounds: {
        north: 56,
        south: 24,
        east: -65,
        west: -126
      }
    },
    styles:[
        {
          "featureType": "administrative",
          "elementType": "labels",
          "stylers": [
            {
              "lightness": 40
            }
          ]
        },
        {
          "featureType": "administrative.land_parcel",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "administrative.neighborhood",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "landscape.natural",
          "stylers": [
            {
              "lightness": 55
            },
            {
              "visibility": "simplified"
            }
          ]
        },
        {
          "featureType": "poi.business",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "labels.text",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "road",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "labels",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "water",
          "stylers": [
            {
              "visibility": "simplified"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#1f295c"
            },
            {
              "lightness": 80
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "labels.text",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        }
    ]
  };

  loadMap();

  window.initMap = initMap;

  function loadMap() {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${APIKey}&libraries=geometry&callback=initMap`;
    script.defer = true;
    document.head.appendChild(script);
  }

  function initMap() {
    map = new google.maps.Map(document.getElementById("google-map"), options);

    getMyLocations(locationsRSS).then(officeLocations => {
      if (typeof officeLocations === "object" && officeLocations.length > 0) {
        let id = 0;
        officeLocations.forEach((loc) => {
          if (!loc.latitude || !loc.longitude) return;
          loc.icon = loc.company + ".svg";
          addMarker(loc, id++);
        });
      }
    });

    infoWindow = new google.maps.InfoWindow({
      maxWidth: 250,
    });

    // const kmlLayer = new google.maps.KmlLayer({
    //   url: "https://dev.sam-cms.com/content/includes/sd-routes.kml",
    //   map: map
    // });

    myRoutes.forEach(route => {
      let coords = route.coordinates.split(/\s/);

      coords = coords.map(points => {
        points = points.split(",");
        return {
          lat: Number(points[1]),
          lng: Number(points[0])
        }
      });

      addPolyline(coords, route.color, route.name);
    });
  }

  function addMarker(loc, id) {
    const marker = new google.maps.Marker({
      position: new google.maps.LatLng(loc.latitude, loc.longitude),
      map,
      title: loc.name,
      id: id,
      icon: `/images/southerndevall.com/marker-icons/${loc.icon}`,
      zIndex: 1,
      active: true
    });

    const infoString = `
  <div class="font-primary text-ms-1">
    <p class="font-bold mt-0 my-ms-2">${loc.name}</p>
    <p class="my-ms-2">${loc.street}<br />
      ${loc.city}, ${loc.state} ${loc.zip}</p>
    <p class="my-ms-2">${loc.hours}</p>
    <p class="my-ms-2">
      <a href="tel:${loc.phone}">${loc.phone}</a>
    </p>
    <p class="mb-0">
      <a href="${getDirectionsLink(loc)}" target="_blank">Get Directions</a>
    </p>
  </div>
`;

    marker.addListener("click", () => {
      infoWindow.close();
      infoWindow.setContent(infoString);
      infoWindow.open({
        anchor: marker,
        map: map,
        shouldFocus: true
      });
    });

    map.addListener("click", () => {
      infoWindow.close();
    });
  }

  function addPolyline(coords, color = "#1F295C", label = "") {

    const line = new google.maps.Polyline({
      path: coords,
      map: map,
      geodesic: true,
      strokeColor: color,
      strokeOpacity: 0.9,
      strokeWeight: 4,
      clickable: true
    });

    const infoString = `
  <div class="font-primary font-bold text-ms-1">
    ${label}
  </div>
`;

    line.addListener("click", (event) => {
      infoWindow.close();
      infoWindow.setContent(infoString);
      infoWindow.setPosition(event.latLng);
      infoWindow.open(map);
    });

    map.addListener("click", () => {
      infoWindow.close();
    });
  }

  function getDirectionsLink(li) {
    var url = "https://www.google.com/maps/dir/?api=1&destination=";

    url += encodeURIComponent(li.address + ", " + li.city + ", " + li.state + ", " + li.postalCode);
    url += "&origin=";

    return url;
  }

  async function getMyLocations(rss) {
    const myLocations = [];
    await fetch(rss)
      .then(response => response.text())
      .then(str => new DOMParser().parseFromString(str, "text/xml"))
      .then(data => {
        data.querySelector("locations").querySelectorAll("item").forEach(item => {
          let loc = {
            name: item.querySelector("name").textContent,
            company: item.querySelector("company").textContent,
            street: item.querySelector("street").textContent,
            city: item.querySelector("city").textContent,
            state: item.querySelector("state").textContent,
            zip: item.querySelector("zip").textContent,
            phone: item.querySelector("phone").textContent,
            hours: item.querySelector("hours").textContent,
            latitude: item.querySelector("latitude").textContent,
            longitude: item.querySelector("longitude").textContent
          };
          myLocations.push(loc);
        });
      });
    return myLocations;
  }
}
