document.querySelectorAll("button[aria-controls^='timeline-content']").forEach(button => {
  const content = document.querySelector("#" + button.getAttribute("aria-controls"));
  button.addEventListener("click", () => {
    if (button.getAttribute("aria-expanded") === "false") {
      content.querySelectorAll("div:nth-child(n+3)").forEach(entry => {
        entry.classList.add("!block");
      });
      button.textContent = "Collapse";
      button.ariaExpanded = true;
    } else {
      content.querySelectorAll("div:nth-child(n+3)").forEach(entry => {
        entry.classList.remove("!block");
      });
      button.textContent = "Expand";
      button.ariaExpanded = false;
    }
  });
});

document.querySelectorAll(".timeline-container [itemprop='itemListElement']").forEach(entry => {
  const chunkName = entry.getAttribute("data-name");
  console.log(chunkName.indexOf("--"));
  if (chunkName.indexOf("--") >= 2) {
    entry.querySelector("h3").insertAdjacentHTML("beforeend", `<span class="md:hidden"> - ${chunkName.split("--")[0]}</span>`);
  }
});
