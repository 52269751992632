document.querySelectorAll(".animated-barge").forEach(bargeSection => {
  let intersectionCallback = (entries, observer) => {
    entries.forEach(entry => {
      if (!entry.isIntersecting) return;
      let section = entry.target;
      section.querySelector("img.barge").classList.add("animated");
      observer.unobserve(section);
      observer = null;
    });
  };

  let options = {
    rootMargin: '0px',
    threshold: 0
  }

  let observer = new IntersectionObserver(intersectionCallback, options);
  observer.observe(bargeSection);
});
